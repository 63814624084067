/****************
  Team
****************/
.section-tittle {
  padding-bottom: 40px;
}

.team {
  position: relative;

  .team-detail {
    h6 {
      &:hover {
        color: $primary;
      }

    }

    background: $gray-1;
    padding: 30px;
  }

  text-align: center;
  overflow: hidden;

  h5 {
    line-height: 24px;
  }

  .team-contact span {
    display: block;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;

    i {
      padding-right: 5px;
    }

  }

}

/* Team Hover */
.team {
  .team-overlay {
    position: absolute;
    bottom: -40px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    background-color: $gray-8;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px;

    .team-overlay-title {
      margin-bottom: 30px;

      span {
        font-size: 12px;
        color: $gray-3;
      }

      h6 {
        color: $primary;
      }

    }

    .team-list {
      padding-left: 0;
      margin-bottom: 30px;

      li {
        display: block;
        color: $white;
        margin-bottom: 10px;
      }

    }

  }

  &:hover .team-overlay {
    opacity: 1;
    bottom: 0;
  }

  .team-overlay {
    .team-list li span {
      color: $primary;
    }

    .team-contant {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      color: $gray-3;
    }

    .team-btn {
      background: $primary;
      color: $white;
      height: 40px;
      width: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      line-height: 40px;

      &:hover {
        background-color: $white;
        color: $primary;
      }

    }

  }

}

@media (max-width:767px) {
  .team .team-overlay .team-contant p {
    display: none;
  }

  .team .team-overlay {
    padding: 20px;
  }

}
