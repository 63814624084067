/****************
  Countdown
****************/
.countdown {
  display: inline-block;
  text-align: center;
  margin-right: 40px;
  width: 120px;
  height: 120px;
  line-height: 70px;
  font-size: 60px;
  color: $white;
  border: 2px solid $primary;
  border-radius: 50%;

  span {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: $white;
  }

  p {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0;
    color: $white;
  }

}

.countdown-dark .countdown {
  span, p {
    color: $gray-8;
  }

}
