/****************
  Testimonial
****************/
.testimonial {
  text-align: center;
}

.testimonial.testimonial-style-1, .testimonial.testimonial-style-2 {
  background: none;
  position: relative;
  margin-top: 50px;

  .testimonial-quote-icon {
    font-size: 100px;
    line-height: 80px;
    color: rgba($gray-8, 0.1);
    text-align: center;
  }

  .testimonial-info {
    position: relative;
    background: $gray-9;
    padding: 50px 50px;
    border-radius: 5px;
    box-shadow: 0 2px 25px rgba($black, 0.05);
    margin-top: -30px;

    .list-unstyled {
      padding-bottom: 20px;
    }

    .testimonial-content {
      margin-bottom: 15px;
      color: $body-color;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }

  }

  .avatar img {
    width: 70px;
    box-shadow: 0 3px 15px rgba($black, 0.05);
  }

  .testimonial-name {
    padding-top: 20px;

    .author-name, span {
      display: inline-block;
      margin: 0;
      font-weight: 600;
    }

  }

  .testimonial-details {
    position: relative;
    margin-top: -40px;
  }

}

.owl-carousel .testimonial.testimonial-style-1, .owl-carousel .testimonial.testimonial-style-2 {
  margin-top: 0;
}

.testimonial.testimonial-style-1 {
  .testimonial-content, .testimonial-name {
    text-align: center;
  }

  .avatar {
    transform: inherit;
    text-align: center;
  }

}

.owl-carousel .owl-item .testimonial img {
  display: inline-flex;
}

.testimonial {
  &.testimonial-style-1 .testimonial-name span {
    color: $gray-3;
    font-weight: 500;
    display: block;
  }

  &.testimonial-style-2 {
    .testimonial-name span {
      font-weight: 500;
      display: block;
    }

    .testimonial-quote-icon {
      text-align: left;
      padding-left: 2px;
    }

    .testimonial-info {
      .testimonial-content {
        text-align: left;
      }

      padding: 50px 50px 50px 0px;
      background: inherit;
      box-shadow: inherit;
    }

  }

}

.testimonial.testimonial-style-2 .testimonial-quote-icon {
  color: rgba($gray-1, 0.3);
}

.testimonial.testimonial-style-2.testimonial-light .testimonial-quote-icon {
  color: rgba($body-color, 0.3);
}

.testimonial {
  &.testimonial-style-2 {
    text-align: left;
  }

  &.testimonial-style-3 {
    text-align: left;
    display: flex;
  }

}

.testimonial-style-3 {
  .avatar {
    width: 150px;
    display: inline-block;
    margin-right: 15px;
  }

  .testimonial-quote-icon {
    position: absolute;
    right: 30px;
    bottom: 0;
    font-size: 50px;
    line-height: 50px;

    i {
      color: transparent;
      -webkit-text-stroke: 2px;
      -webkit-text-stroke-color: rgba($gray-3, 0.3);
    }

  }

  .testimonial-content {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 26px;
    color: $body-color;
  }

}

.testimonial-style-3.testimonial-light {
  .testimonial-content {
    color: $white;
  }
  .testimonial-details {
    span {
      color: $white;
    }
    .author-name {
      color: $primary;
    }
  }
}
