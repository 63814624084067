/****************
  Feature Box
****************/
.feature-box {
  display: flex;

  .feature-icon {
    margin: 5px 15px 0 0;
    font-size: 25px;
    line-height: 26px;
    color: $primary;
    display: flex;
    align-items: flex-start;
  }

  &.feature-box-icon-position-right .feature-icon {
    order: 2;
    margin: 5px 0 0 15px;
    display: flex;
    align-items: flex-start;
  }

}

/* feature box style 2 */
.feature-box-style-2 {
  .feature-box-inner {
    position: relative;
    overflow: hidden;
  }

  .feature-hover-bg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0;
    background: $gray-8;
    opacity: 0;
    z-index: 0;
    transition: all .3s ease-in-out;
  }

  .feature-image {
    position: relative;
    top: 0;
    padding: 0 0;
    transition: all .3s ease-in-out;
  }

  .feature-box-inner {
    .feature-content {
      position: relative;
      text-align: center;
      margin-top: 30px;
      padding: 0 10px;
    }

    .feature-description {
      position: absolute;
      z-index: -1;
      top: 100%;
      padding-top: 20px;
      padding-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px;
      opacity: 0;
      transition: all .3s ease-in-out;
    }

    .description {
      color: $gray-3;
      font-size: 14px;
      line-height: 24px;
    }

    .feature-btn {
      display: inline-block;
      background: $primary;
      font-size: 13px;
      line-height: 18px;
      color: $white;
      padding: 10px 15px;
      text-decoration: none;
      margin-top: 20px;
    }

    &:hover {
      z-index: 8;
      overflow: visible;

      .feature-hover-bg {
        opacity: 1;
      }

      .feature-image {
        top: -10px;
        padding: 0 15px;
      }

      .feature-description {
        opacity: 1;
        z-index: 1;
        transition-delay: 0.1s;
      }

    }

    .feature-btn:hover {
      background: $white;
      color: $primary;
    }

    &:hover .feature-title h6 {
      color: $white;
    }

    &:not(:hover) .feature-hover-bg {
      margin-bottom: 20px !important;
    }

  }

}

/* feature box style 3 */
.feature-box-style-3 {
  .feature-box-inner {
    background: $gray-9;
    padding: 30px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;

    &:hover {
      z-index: 8;
      overflow: visible;
    }

  }

  .feature-hover-bg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0;
    background: $dark;
    opacity: 0;
    z-index: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }

  .feature-box-inner {
    &:hover .feature-hover-bg {
      opacity: 1;
    }

    &:not(:hover) .feature-hover-bg {
      margin-bottom: 20px !important;
    }

  }

  .feature-icon i {
    font-size: 50px;
    line-height: 50px;
  }

  .feature-box-inner {
    .feature-icon {
      position: relative;
    }

    &:hover .feature-icon i {
      color: $primary;
    }

    .feature-content {
      position: relative;
      text-align: center;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }

    &:hover .feature-title {
      color: $primary;
    }

    .feature-description {
      position: absolute;
      z-index: -1;
      top: 100%;
      padding-top: 20px;
      padding-bottom: 30px;
      opacity: 0;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      padding-bottom: 0;
    }

    &:hover .feature-description {
      opacity: 1;
      z-index: 1;
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
    }

  }

  ul.feature-list {
    padding-left: 0;
    text-align: center;
    margin-bottom: 0;
  }

  .feature-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: $white;
    font-size: 16px;
    align-items: center;

    i {
      font-size: 12px;
      margin-left: 10px;
    }

  }

}

.feature-box-style-3 .border-hover .feature-hover-bg {
  border: 2px solid $primary;
}
