/****************
  Newsletter
****************/
.newsletter {
  .form-group {
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
  }

  .form-control {
    border: none;
    border-radius: 0px;
    height: 50px;
    padding: 10px 20px;
    box-shadow: none;
    font-size: 12px;
    font-weight: normal;
    color: $gray-8;

    &::placeholder {
      color: $gray-3;
    }

  }

}

.newsletter-dark .form-control {
  background: $gray-9;
  border-color: $gray-9;

  &::placeholder {
    color: $gray-3;
  }

}

.bg-overlay-black {
  &:before {
    background: rgba($dark, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}
.newsletter-dark {
  .btn {
    &:hover {
      background: $gray-8;
      color: $white;
    }
  }
}

