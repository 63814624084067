/****************
	Social Icons
****************/
.social-icons {
	ul {
		padding-left: 0;
		margin-bottom: 0;
	}

	li {
		display: inline-block;
		margin-bottom: 10px;

		a {
			font-size: 18px;
			color: $gray-3;
			line-height: 24px;

			&:hover {
				color: $primary;
			}

		}

	}

}

.social-style-flat .social-icons li a {
	background: $gray-8;
	color: $primary;
	height: 40px;
	width: 40px;
	line-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;

	&:hover {
		background: $primary;
		color: $white;
	}

}

.social-style-border .social-icons li a {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	height: 40px;
	width: 40px;
	line-height: 40px;
	border: 1px solid $primary;
	color: $primary;

	&:hover {
		background: $gray-8;
		color: $white;
		border-color: $gray-8;
	}

}

.social-icons.social-dark li a {
	color: $gray-8;

	&:hover {
		color: $primary;
	}
}
