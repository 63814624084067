/****************
  Button
****************/
.btn {
    font-size: 14px;
    font-weight: bold;
    padding: 16px 25px;
    line-height: 1;
    position: relative;
    z-index: 1;
    border-radius: inherit;
    border: none;
    transition: all 0.3s ease-in-out;
    box-shadow: none !important;
}

.btn-primary {
    color: $white;
    background-color: $primary;
    border-color: $primary;
    box-shadow: none;

    &:hover {
        background-color: $gray-8;
        border-color: $gray-8;
    }

    &:active {
        background-color: $primary;
        border-color: $primary;
    }

    &:focus {
        background-color: $primary;
        border-color: $primary;
    }

}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: $primary;
    border-color: $primary;
    box-shadow: none;
}

.btn-dark {
    color: $primary;
    background-color: $gray-8;
    border-color: $gray-8;

    &:hover {
        background-color: $primary;
        border-color: $primary;
    }

    &:active {
        background-color: $primary;
        border-color: $primary;
    }

    &:focus {
        background-color: $primary;
        border-color: $primary;
    }

}

.btn-light {
    color: $gray-8;
    background-color: $gray-1;
    border-color: $gray-1;

    &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }

    &:active {
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }

    &:focus {
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }

}

.btn-white {
    background: $white;
    border-color: $white;
    color: $gray-8;

    &:hover {
        background: $primary;
        border-color: $primary;
        color: $white;
    }

    &:active {
        background: $primary;
        border-color: $primary;
        color: $white;
    }

    &:focus {
        background: $primary;
        border-color: $primary;
        color: $white;
    }

}

.btn-half {
    &:before {
        content: '';
        width: 60px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        z-index: -1;
    }

    &:hover:before {
        width: 100%;
    }

}

.btn-half-right:before {
    right: 0;
    left: inherit;
}

.btn-primary.btn-half {
    color: $gray-8;
    background: none;

    &:before {
        background-color: $primary;
    }

    &:hover {
        color: $white;
        background: none;
    }

}

.btn-dark.btn-half {
    color: $primary;
    background: none;

    &:before {
        background-color: $gray-8;
    }

    &:hover {
        color: $white;
        background: none;
    }

}

.btn-light.btn-half {
    color: $gray-8;
    background: none;

    &:before {
        background-color: $gray-1;
    }

    &:hover {
        color: $gray-8;
        background: none;
    }

}

.btn-white.btn-half {
    color: $primary;
    background: none;

    &:before {
        background-color: $white;
    }

    &:hover {
        color: $primary;
        background: none;
    }

}

.btn-hover-white:hover {
    background-color: $white;
    border-color: $white;
    color: $gray-8;
}

.btn.btn-sm {
    padding: 10px 24px;
}

.btn.btn-lg {
    padding: 18px 20px;
    font-size: 18px;
}

.btn-link {
    color: $primary;

    &:hover {
        color: $primary;
    }

}
