/****************
  Accordion
****************/
.accordion {
	.card {
		border: none;
		padding-bottom: 15px;

		.card-header {
			background: none;
			border-bottom: none;
			padding: 0;

			button {
				position: relative;
				font-size: 18px;
				line-height: 1.5;
				color: $primary;
				padding: 10px 30px 10px 75px;
				width: 100%;
				text-align: left;
				border: none;

				&:before {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0px;
					content: "\f063";
					font-size: 14px;
					font-family: "Font Awesome 5 Free";
					width: 46px;
					height: 46px;
					line-height: 46px;
					z-index: 9;
					font-weight: 900;
					text-align: center;
					background-color: $primary;
					color: $white;
				}

				&:hover {
					text-decoration: none;
				}

				&:focus {
					text-decoration: none;
				}

			}

		}

		.card-body {
			padding: 10px 30px 15px 75px;
		}

	}

}

.accordion .card-header button.collapsed {
	color: $gray-8;

	&:before {
		content: "\f061";
		background-color: $gray-8;
	}

}

/* accordion-02 */
.accordion.accordion-02 {
	.card {
		padding: 8px 0px;

		.card-header {
			button {
				font-size: 14px;
				padding: 0px 0px 0px 30px;

				&:before {
					width: 15px;
					height: 15px;
					line-height: 12px;
					font-size: 8px;
					left: 0px;
				}

				&:after {
					display: none;
				}

			}

		}

		.card-body {
			padding: 10px 0px 0px 30px;
		}

	}

}
