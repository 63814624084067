/****************
  Case Studies
****************/
.filters-group {
  display: table;
  text-align: center;
  margin: 0 auto;
}

.my-shuffle-container.columns-5 .grid-item {
  width: 20%;
  padding: 5px;
}

.filters-group button {
  padding-right: 20px;
  cursor: pointer;
  font-size: 14px;
  background: transparent;
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: 500;
  line-height: 18px;
  border-radius: 3px;
  padding-bottom: 20px;

  span {
    padding-left: 20px;
  }

  &.active {
    color: $primary;
  }

}

.case-studies-item {
  position: relative;
  overflow: hidden;

  .case-studies-overlay {
    padding: 20px;
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &:hover .case-studies-overlay {
    opacity: 1;
    background-color: $gray-8;
    bottom: 0;
  }

  .case-studies-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h6 {
      color: $white;
    }

    .case-studies-category {
      width: 100%;
    }

    span {
      color: $primary;
    }

  }

  .case-studies-btn {
    background: $primary;
    color: $white;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    margin-top: 15px;

    &:hover {
      background-color: $white;
      color: $primary;
    }

  }

}

.case-studies-item .case-studies-overlay .case-studies-info span a {
    color: $primary;

    &:hover {
      color: $white;
    }
}

button:focus {
  outline: none;
}

.my-shuffle-container.columns-3 .grid-item {
  width: 33.3%;
  padding: 5px;
}

.navigation.post-navigation {
  .nav-link {
    display: flex;

    a {
      span {
        display: block;
        color: $gray-8;
        transition: all 0.5s ease-in-out;
      }

      .nav-title {
        font-size: 16px;
        line-height: 24px;
      }

    }

    .nav-previous {
      width: 50%;
      padding-right: 200px;

      a {
        display: flex;
        align-items: center;

        .navigation-img {
          width: 100px;
          margin-right: 15px;
          position: relative;
        }

        &:hover {
          span {
            color: $primary;
          }

          &:before {
            background-color: $primary;
          }

        }

      }

    }

    .nav-next {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      width: 50%;
      padding-left: 200px;

      a {
        display: flex;
        align-items: center;

        .navigation-img {
          width: 100px;
          margin-left: 15px;
          position: relative;
        }

        &:hover {
          span {
            color: $primary;
          }

          &:before {
            background-color: $primary;
          }

        }

      }

    }

  }

}

.single-pagination {
  a {
    color: $gray-8;

    &:hover {
      color: $primary;
    }
  }

  .navigation-img {
    position: relative;
    overflow: hidden;
    height: 80px;
    width: 130px;

    img {
      object-fit: scale-down;
    }

  }

  a .navigation-img i {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: $gray-8;
    color: $primary;
    font-size: 20px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

}

.single-pagination a:hover .navigation-img i {
  opacity: 1;
}

.single-pagination .navigation-title span {
  font-weight: 700;
  font-size: 16px;
}

.filters-group.case-studies-btn-dark button {
  color: $white;
}

.filters-group.case-studies-btn-dark button.active {
  color: $primary;
}

@media (max-width:991px) {
  .my-shuffle-container.columns-5 .grid-item {
    width: 33.3%;
  }

}

@media (max-width:767px) {
  .my-shuffle-container.columns-5 .grid-item, .my-shuffle-container.columns-3 .grid-item {
    width: 50%;
  }

}

@media (max-width:479px) {
  .my-shuffle-container.columns-5 .grid-item, .my-shuffle-container.columns-3 .grid-item {
    width: 100%;
  }

}
