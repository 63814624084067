/****************
  History
****************/
.timeline-item {
  .timeline {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 30px;
      bottom: 80px;
      left: 110px;
      width: 1px;
      background: $dark;
    }

    li {
      .timeline-year {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba($gray-8, 0.2);
        margin-right: 70px;
        padding: 10px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: $primary;
        min-width: 80px;
        min-height: 80px;
      }

      .timeline-content {
        margin-top: 25px;
      }

      .timeline-title {
        position: relative;
        color: $primary;
        margin-bottom: 15px;

        &:before {
          content: "";
          width: 13px;
          height: 13px;
          position: absolute;
          top: 3px;
          left: -46px;
          background: $primary;
          border-radius: 50px;
        }

      }

    }

  }

  &.timeline-light .timeline {
    &:before {
      background: rgba($white, 0.5);
    }

    li {
      .timeline-year {
        border-color: rgba($white, 0.5);
      }

      .timeline-content {
        color: $white;
      }

    }

  }

}
