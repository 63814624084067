/****************
  Header
****************/
.header {
  background: $white;

  .container-fluid {
    padding: 0 60px;
  }

}

/* topbar */
.header .topbar {
  background: $dark;
  padding: 15px 0;

  a {
    color: $white;

    &:hover {
      color: $primary;
    }

  }

  ul {
    display: flex;
    margin-bottom: 0;

    li {
      padding-right: 20px;
    }

    a {
      color: $white;

      &:hover {
        color: $primary;
      }

    }

  }

}

.header .topbar ul li:last-child {
  padding-right: 0;
}

.navbar-brand .logo-sticky {
  display: none;
}

.header .topbar .dropdown .dropdown-toggle {
  padding: 12px 0;

  i {
    vertical-align: text-top;
  }
}

.topbar .dropdown .dropdown-menu {
  border-radius: 0;
  border: none;
  box-shadow: 0px 5px 10px rgba($black, 0.1);
  padding: 10px;

  a {
    color: $gray-8;
    font-size: 14px;
    padding: 5px 12px;
    font-weight: 500;
    border-radius: 3px;
  }

}

.header .topbar {
  .dropdown .dropdown-menu a:hover {
    color: $primary;
  }

  .language img {
    height: 16px;
  }

}

/* navbar */
.header.default .navbar {
  background: $gray-8;
  padding: 0;

  .navbar-nav {
    .nav-link {
      font-weight: 600;
      font-size: 14px;
      padding: 10px 15px;
      color: $white;
      text-transform: capitalize;

      i {
        font-weight: bold;
      }

      &:hover {
        color: $primary;
      }

    }

  }

}

.header.default .navbar .navbar-nav .nav-item .nav-link {
  position: relative;
  left: 0;
}

.header.default .navbar .navbar-nav .nav-item.active .nav-link, .header.default .navbar .navbar-nav .nav-item .nav-link:hover {
  color: $white;
}

.header.default .navbar .navbar-nav .nav-item.active .nav-link, .header.default.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
  color: $primary;
}

.header.default .navbar .navbar-nav .nav-item .nav-link:hover,
.header.header-02 .navbar .navbar-nav .nav-item .nav-link:hover {
    color: $primary;
}

.header .navbar .navbar-nav .nav-item .nav-link span {
  padding-left: 30px;
}

.header.default .navbar .navbar-nav .nav-item.active .nav-link span, .header.header-02 .navbar .navbar-nav .nav-item.active .nav-link span {
  color: $white;
}

.header.default .navbar .navbar-nav .nav-item .nav-link:hover span, .header.header-02 .navbar .navbar-nav .nav-item .nav-link:hover span {
  color: $white;
}

.header.default.is-sticky .navbar .navbar-nav .nav-item.active .nav-link span, .header.header-02.is-sticky .navbar .navbar-nav .nav-item.active .nav-link span {
  color: $gray-8;
}

.header.default.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover span, .header.header-02.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover span {
  color: $gray-8;
}

.dropdown-menu {
  z-index: 9999;
  border-radius: inherit;
}

.navbar-collapse {
  position: relative;
}

.navbar-light {
  color: $white;
  background-color: $gray-1;
  border-color: $border-color;

  .navbar-nav {
    >li {
      >a {
        color: $white;
      }

    }

    >.dropdown {
      >a {
        .caret {
          border-top-color: $white;
          border-bottom-color: $white;
        }

      }

    }

  }

}

.navbar {
  .navbar-brand {
    padding: 17px 0px;
    margin-right: 0;
    color: $white;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 180px;
    flex: 0 0 180px;
    -webkit-transition: none;
    transition: none;

    img {
      height: 70px;
    }

  }

}

.header {
  .navbar-nav {
    li {
      >a {
        align-items: center;
        display: flex;
        padding: 3px 0px;
        color: $gray-8;
        text-transform: capitalize;
        font-size: 14px;

        &:hover {
          color: $primary;
        }

        i {
          margin-left: 5px;
          font-size: 10px;
        }

      }

    }

  }

}

/* Search */
.header .search {
  margin-right: 30px;

  .search-btn {
    display: block;
    height: 22px;
    line-height: 22px;
    position: relative;
    text-indent: -9999px;
    width: 14px;
    transition: color 300ms ease 0s;
    transition: 0.5s ease-in-out;
    color: $white;

    &:hover {
      color: $primary;
    }

    &:before {
      content: "\f002";
      font-family: "Font Awesome 5 Free";
      font-size: 16px;
      font-weight: 900;
      left: 0;
      position: absolute;
      text-indent: 0;
      top: 0;
    }

  }

  .search-box {
    display: block;
    position: absolute;
    right: 290px;
    top: 100%;
    visibility: hidden;
    width: 300px;
    z-index: 888;
    transform: rotateX(90deg);
    transform-origin: 0px 0px;
    opacity: 0 !important;
    margin: 0px;
    transition: all 400ms ease 0s;
    box-shadow: $box-shadow;

    .form-control {
      height: 58px;
      background-color: $white;
      padding-right: 60px;
      border: none;

      &:focus {
        border: 1px solid $border-color;
      }

    }

    .search-button {
      cursor: pointer;
      font-size: 16px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
      transition: all 0.5s ease-in-out 0s;
    }

  }

}

.header.is-sticky .search .search-btn {
    color: $gray-8;

    &:hover {
      color: $primary;
    }
}

.search.search-open {
  .search-box {
    transform: rotateX(0deg);
    opacity: 1 !important;
    visibility: visible !important;
  }

}

.header.default.is-sticky .btn-hover-white:hover {
    background: $gray-8;
    color: $white;
    border-color: $gray-8;
}

/* Header 02 */
.header.header-02 {
  background: $gray-8;

  .navbar-nav {
    .nav-link {
      font-weight: 600;
      font-size: 14px;
      padding: 10px 15px;
      color: $white;
      text-transform: capitalize;

      i {
        font-weight: bold;
      }

      &:hover {
        color: $primary;
      }

    }

  }

  .navbar-brand {
    margin-right: 0;

    img {
      height: 60px;
    }

  }

  .navbar {
    padding-top: 10px;
    padding-bottom: 15px;
  }

}

.header.header-02.is-sticky .navbar-brand img {
  height: 50px;
  display: none;
}

.header.header-02.is-sticky .navbar-brand img.logo-dark {
    display: block;
}

.header.header-02 .topbar {
  background: none;
}

.header.header-02 .topbar {
  background: $gray-8;

  .phone {
    font-size: 18px;
    color: $white;
    font-weight: bold;

    i {
      font-size: 20px;
      color: $primary;
    }

  }

  .navbar-brand {
    padding: 10px;
  }

  .add-listing {
    .login {
      font-size: 20px;
      color: $gray-8;
    }

  }

}

.header.header-02 .navbar {
  .navbar-brand {
    display: none;
  }

  .add-listing {
    display: none;

    .login {
      font-size: 20px;
      color: $white;
    }

  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        position: relative;
        font-size: 14px;
        color: $white;
        left: 0;
      }

    }

    .nav-item.active {
      .nav-link {
        color: $primary;

        &:before {
          width: 15px;
          right: calc(100% - 15px);
        }

      }

    }

  }

}

.navbar .dropdown > .dropdown-menu li.active > a {
  color: $primary;
}

.header.header-02 {
  .is-sticky.navbar {
    .navbar-brand {
      display: inline-block;
    }

    .add-listing {
      display: inline-block;
    }

    .navbar-nav {
      .nav-link {
        padding-bottom: 20px;
      }

    }

  }

}

.header.header-02 button {
  color: $primary;
}


/* header 03 */
.header.header-03 .topbar {
  background: $white;
  border-bottom: 1px solid $border-color;

  a {
    color: $gray-8;
  }
}

.header.header-03 .navbar {
    background: $white;
    padding: 0;
}


.header.header-03.is-sticky .topbar {
    display: none;
}

.header.header-03 .search .search-btn {
  color: $gray-8;

  &:hover {
    color: $primary;
  }
}


.megamenu .card {
  outline: none;

  &:hover {
    outline: 1px solid $black;
  }

  &:focus {
    outline: 1px solid $black;
  }

}

.megamenu.disabled {
  >a {
    &:hover {
      color: $gray-1;
      text-decoration: none;
      background-color: transparent;
      background-image: none;
      cursor: not-allowed;
    }

    &:focus {
      color: $gray-1;
      text-decoration: none;
      background-color: transparent;
      background-image: none;
      cursor: not-allowed;
    }

  }

}

.megamenu.dropdown-header {
  color: $primary;
  font-size: 18px;
}

.header {
  li > .dropdown-item {
    &:focus, &:hover {
      color: $primary;
      background: none;
    }

  }

  .dropdown-item {
    &.active, &:active, &:focus, &:hover {
      background: none;
    }

  }

  .dropdown-toggle::after {
    content: none;
  }

  .navbar-collapse {
    align-items: inherit;
  }

  .megamenu .dropdown-item {
    padding: 0px;
  }

  .navbar .dropdown-menu {
    a.dropdown-item {
      min-width: 180px;
    }

    padding: 20px 30px;

    li a i {
      margin-left: auto;
    }

  }

}

.navbar {
  .dropdown {
    >.dropdown-menu {
      li {
        >a {
          font-size: 14px;
          font-weight: 500;
          padding: 10px 20px 10px 0;
          position: relative;
        }

      }

    }

  }

}

/* header sticky */
@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }

}

.header.is-sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  background-color: $white;
  box-shadow: $box-shadow;
  padding: 0px;
  backdrop-filter: blur(20px) saturate(180%);
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.header.default.is-sticky {
  .topbar {
    display: none;
  }

  .navbar {
    background-color: $white;
    box-shadow: $box-shadow;

    .navbar-nav {
      .nav-item {
        .nav-link {
          font-size: 14px;
          color: $gray-8;

          &:hover {
            color: $gray-8;
          }

        }

      }

    }

    .navbar-brand {
      .logo {
        display: none;
      }

      .logo-sticky {
        display: inline-block;
      }

    }

  }

}

.header.header-02.is-sticky {
  .topbar {
    display: none;
  }

  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;

    .navbar-brand {
      display: inline-block;
    }

    .add-listing {
      display: inline-block;
    }

    .navbar-nav {
      .nav-link {
        color: $gray-8;
      }

    }

  }

}

.place-card-large {
  display: none !important;
}

.header .navbar .dropdown-menu.megamenu .nav-title {
  padding-top: 10px;
}

.header.default.is-sticky .navbar .navbar-brand img {
  height: 50px;
}

@media (min-width:992px) {
  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border: none;
    left: 100%;
    transform: translate3d(-50%, 10px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 5px 10px rgba($black, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important;
  }

  .header .navbar .dropdown-menu.megamenu {
    left: 50%;
  }

  .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: auto;
  }

  .navbar-nav .mega-menu {
    position: static;
  }

  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
    opacity: 1;
  }

  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
    left: 100%;
    right: auto;
    transform: translate3d(15px, 10px, 0);
  }

  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
    right: 100%;
    left: auto;
    transform: translate3d(-15px, 10px, 0);
  }

  .navbar-nav li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
  }

  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
  }

}
