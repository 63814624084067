// Font family
$font-base: 		    "Libre Baskerville", sans-serif;
$font-hedding:		  "Libre Baskerville", sans-serif;

// Colors
$body-color:		#666666;
$primary:				#c11212;
$dark: 		      #242323;
$white:					#ffffff;
$gray-1: 				#f1f1f1; 	// bg light
$gray-2: 				#fdfdfc;
$gray-3: 				#999999; 	// body-text
$gray-4: 				#707173;
$gray-5: 				#3a4957;
$gray-6: 				#293745;
$gray-7: 				#c11212;
$gray-8: 				#242323; 	// Hedding color
$gray-9: 				#f1f1f1; 	// bg color
$black:  				#000000;

// Border color
$border-color: 	rgba($gray-8, 0.1);

// For button and input border radius
$border-radius: 5px;

// Define common padding and border radius sizes and more.
$box-shadow-sm:		0px 5px 16px 0px rgba($gray-8, .15);
$box-shadow:			0px 10px 10px 0px rgba($gray-8, 0.04);
$boxshadow-lg:		0 1rem 3rem rgba($gray-8, .175);

