body {
	font-family: $font-base;
	font-weight: 500;
	font-style: normal;
	font-size: 14px;
	color: $body-color;
	overflow-x: hidden;
	line-height: 1.9;
}

a {
	outline: medium none !important;
	color: $gray-8;
	transition: all 0.3s ease-in-out;

	&:focus {
		color: $primary;
		text-decoration: none !important;
	}

	&:hover {
		color: $primary;
		text-decoration: none !important;
	}

}

input {
	outline: medium none !important;
	color: $primary;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-hedding;
	font-weight: normal;
	color: $gray-8;
	margin-top: 0px;
	font-weight: 700;
	line-height: 1.2;

	a {
		color: inherit;
	}

}

label {
	font-weight: normal;
}

h1 {
	font-size: 48px;
	font-style: normal;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 18px;
}

p {
	line-height: 1.9;
}

*::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

/* container-fluid */
.container-fluid.container-space {
	padding: 0 100px;
}

/* form-control */
.form-control {
	border: 1px solid $border-color;
	border-radius: 0px;
	height: 48px;
	padding: 10px 20px;
	box-shadow: none;
	font-size: 14px;
	font-weight: 500;
	color: $gray-8;
	background-clip: inherit !important;

	&:focus {
		box-shadow: none;
		border-color: $primary;
	}

}

.form-control::-moz-placeholder {
	color: $white;
}

.form-control::-ms-input-placeholder {
	color: $white;
}

.form-control::-webkit-input-placeholder {
	color: $white;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top {
	z-index: 9;
}

/* form-dark */
.form-dark .form-control {
	background-color: $gray-9;
	color: $white;

	&:focus {
		box-shadow: $box-shadow;
		border-color: $primary;
	}

}

.form-dark .form-control::-moz-placeholder {
	color: $white;
}

.form-dark .form-control::-ms-input-placeholder {
	color: $white;
}

.form-dark .form-control::-webkit-input-placeholder {
	color: $white;
}

/* custom-file */
.custom-file {
	height: 50px;

	.custom-file-input {
		height: 50px;
		color: $gray-8;
		border-radius: $border-radius;
		border-color: $border-color;

		&:focus {
			box-shadow: none;
			border-color: $primary;
		}

	}

	.custom-file-label {
		height: 50px;
		font-size: 14px;
		font-weight: 600;
		color: $gray-8;
		border-color: $border-color;
		padding: 15px 90px 15px 20px;
		border-radius: $border-radius;

		&:after {
			height: 48px;
			padding: 14px 25px;
		}

	}

}

.custom-file-input:focus~.custom-file-label {
	box-shadow: none;
	border-color: $primary;
}

/* border */
.border {
	border-color: $border-color !important;
}

.border-top {
	border-top-color: $border-color !important;
}

.border-left {
	border-left-color: $border-color !important;
}

.border-right {
	border-right-color: $border-color !important;
}

.border-bottom {
	border-bottom-color: $border-color !important;
}

/* Back To Top */
.back-to-top {
	position: fixed;
	bottom: 30px;
	right: 30px;
	display: inline-block;
	font-size: 20px;
	color: $white;
	background: $primary;
	text-align: center;
	width: 45px;
	height: 45px;
	line-height: 45px;
	overflow: hidden;
	cursor: pointer;
	border-radius: inherit;
	z-index: 9;

	&:hover {
		color: $white;
	}

	&:focus {
		color: $white;
	}

}

/* breadcrumb */
.breadcrumb {
	padding-left: 0;
	background: transparent;
	font-family: $font-hedding;
	font-weight: 500;
	display: inline-flex;

	.breadcrumb-item {
		padding-right: 5px;
		color: $white;

		&:before {
			color: $white;
		}

		a {
			color: $white;

			&:hover {
				color: $primary;
			}

		}

		span {
			padding-left: 8px;
		}

	}

	.active {
		span {
			color: $primary;
		}

	}

}

/* pagination */
.pagination {
	.page-item {
		.page-link {
			padding: 18px 28px;
			color: $gray-3;
			border-color: $border-color;

			&:focus {
				box-shadow: none;
			}

		}

	}

	.page-item.active {
		.page-link {
			background: $primary;
			border-color: $primary;
			color: $white;
		}

	}

}
