ul {
  padding-left: 0;
  margin-bottom: 0;
}

section {
  position: relative;
}

/* section title */
.section-title {
  margin-bottom: 50px;
}

.section-title h2, .section-title h1 {
  margin-bottom: 20px;
}

.section-title p {
  font-weight: 600;
}

.section-title .pre-title {
  color: $primary;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

/* Bg overlay */
.bg-overlay-left {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: $gray-9;
    width: 38%;
    height: 63%;
    border-radius: 0 5px 5px 0;
  }

  .section-contant {
    padding-left: 70px;
  }

}

.bg-overlay-left.bg-overlay-light {
  &:before {
    background-color: $gray-1;
  }

}

.bg-overlay-right {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    background-color: $gray-7;
    width: 30%;
    height: 100%;
  }

}

.bg-overlay-right.bg-overlay-light {
  &:before {
    background-color: $gray-1;
  }

}

.about.bg-overlay-right:before {
  z-index: -1;
}


.bg-light-overlay-left {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: $gray-1;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

}

/* sticky column */
.is-sticky-column {
  position: -webkit-sticky;
  position: sticky;
  top: 130px;
}

.bg-holder-left {
  background-position: top left;
  background-repeat: no-repeat;
}

/* contact form */
.contact-form {
  .input-group {
    width: 100%;
    float: inherit;
    margin-bottom: 30px;
    margin-right: 0;
  }

  .form-control {
    height: 50px;
    font-size: 0.875rem;
    background: none;
    border: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
  }

  textarea.form-control {
    height: auto;
    margin-bottom: 30px;
  }

  .form-control {
    ~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $primary;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      z-index: 9;
    }

    &:focus {
      border-bottom: 1px solid $primary;
      box-shadow: none;
    }

    &::placeholder {
      color: $gray-3;
    }

  }

}

/* video */
.video-btn {
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    border: 1px solid;
    border-radius: 50%;
    animation: pulse-border 1.5s ease-out infinite;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background: $primary;
    border-radius: 50%;
    transition: all 200ms;
    color: $white;

    &:hover {
      color: $white;
    }

  }

  &:hover:after {
    background-color: darken($primary, 10%);
  }

}

.video-image {
  position: relative;

  .video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

/* call */
.border-lr {
  &:before {
    content: "";
    position: absolute;
    border: 1px solid $border-color;
    left: 0;
    width: 40%;
    top: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    border: 1px solid $border-color;
    right: 0;
    width: 40%;
    top: 50%;
  }

  .call-btn {
    color: #c11212;
  }

}

.call-btn {
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    border: 1px solid;
    border-radius: 50%;
    animation: pulse-border 1.5s ease-out infinite;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background: $primary;
    border-radius: 50%;
    transition: all 200ms;
    color: $white;
    font-size: 24px;

    &:hover {
      color: $white;
    }

  }

  &:hover:after {
    background-color: darken($primary, 10%);
  }

}

.call-image {
  position: relative;

  .call-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.call-sub-title {
  font-size: 18px;
  color: $primary;
}

@keyframes pulse-border {
  0% {
    transform: translateX(0%) translateY(0%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) translateY(0%) translateZ(0) scale(1.5);
    opacity: 0;
  }

}

.callout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.callout p {
  margin-bottom: 0;
  margin-right: 15px;
}

/* blockquote */
.blockquote, .title-blockquote {
  font-size: 24px;
  line-height: 36px;
  font-style: italic;
  display: block;

  &:before {
    content: "\201C";
    font-size: 44px;
    color: $primary;
  }

  &:after {
    content: "\201D";
    font-size: 44px;
    color: $primary;
    line-height: 20px;
    position: relative;
    top: 10px;
  }

}

.blockquote-quote {
  position: relative;
  font-size: 18px;
  border-left: 0 solid $primary;
  padding-left: 30px;
  font-style: italic;
  display: block;
  z-index: 1;

  p {
    position: relative;
  }

  i {
    position: absolute;
    z-index: 0;
    top: 20px;
    left: 0;
    font-size: 60px;
    line-height: 60px;
    color: $gray-2;
  }

}

/* blockquote border */
.blockquote-border {
  display: block;
  border-top: 3px solid $primary;
  border-bottom: 3px solid $primary;
  margin-top: 30px;
  padding: 40px 0;
}

.title-blockquote {
  &:before {
    position: relative;
    top: 5px;
    left: -10px;
  }

  &:after {
    top: 5px;
  }

  font-size: 36px;
  color: $white;
  margin-bottom: 30px;
}

/* Home 03 */
.author {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .avatar {
    margin-right: 20px;
    height: 60px;
    width: 60px;
  }
}

/* chart */
#chart {
  max-width: 650px;
  margin: 35px auto;
  .apexcharts-xaxis-label {
    font-weight: bold;
  }
}

.about-section-tittle {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.award-img img {
  width: 170px;
}

.header-inner {
  padding: 180px 0;
}

.dropcaps {
  font-size: 48px;
  line-height: 52px;
  float: left;
  text-align: center;
  margin-right: 10px;
}

.team-list li b {
  font-size: 16px;
}

.coming-soon .social-icons li a {
  color: $white;
  &:hover {
    color: $primary;
  }
}

.team-single.is-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 130px;
}

.map-iframe {
    height: 450px;
}
