/*

Template: Advocatus - Lawyer & Attorney HTML Template
Version: 1.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/

// Core files
@import "variables";
@import "typography";
@import "helpers";

// Shortcodes
@import "shortcodes/button";
@import "shortcodes/accordion";
@import "shortcodes/list";
@import "shortcodes/feature-info";
@import "shortcodes/case-studies";
@import "shortcodes/team";
@import "shortcodes/testimonial";
@import "shortcodes/newsletter";
@import "shortcodes/counter";
@import "shortcodes/owl-carousel";
@import "shortcodes/countdown";
@import "shortcodes/social-icon";
@import "shortcodes/history";
@import "shortcodes/skill";

// Structure
@import "header";
@import "sidebar";
@import "banner";
@import "layout";

// Pages
@import "blog";
@import "error";
@import "footer";

// Responsive
@import "responsive";
