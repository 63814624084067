/****************
  blog
****************/
.blog-post {
	margin-bottom: 30px;

	.blog-post-image {
		img {
			border-radius: $border-radius;
		}

	}

	.blog-post-content {
		text-align: center;
		padding: 25px 30px 40px;
		margin: -45px 25px 0;
		box-shadow: $box-shadow;
		background-color: $white;
		border-radius: $border-radius;
		position: relative;
		z-index: 9;
		transition: all 0.5s ease-in-out;

		.blog-post-date {
			font-size: 12px;
			font-weight: 700;
			color: $gray-3;
			margin-bottom: 8px;
		}

		.blog-post-title {
			font-weight: 700;
			margin-bottom: 0;
		}

	}

	&:hover {
		.blog-post-content {
			background-color: $primary;
			transition: all 0.5s ease-in-out;
			margin-top: -60px;

			.blog-post-title {
				a {
					color: $gray-8;
				}

			}

		}

	}

}

.blog-detail {
	.blog-post {
		margin-bottom: 0px;

		.blog-post-content {
			margin-top: 0;
			top: -45px;
		}

		&:hover {
			.blog-post-content {
				margin-top: 0;
				top: -60px;
			}

		}

	}

	hr {
		margin-top: 70px;
		margin-bottom: 40px;
	}

	.blog-post-social {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;

		span {
			padding-right: 15px;
		}

		ul {
			margin-bottom: 0;

			li {
				display: inline-block;
				float: left;
				padding: 0 6px;

				a {
					color: $gray-8;

					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

	.blog-post-navigation {
		.post-navigation {
			.nav-link {
				position: relative;

				a {
					i {
						position: absolute;
						font-size: 36px;
						color: $gray-8;
						top: 50%;
						transform: translateY(-50%);
					}

					span {
						display: block;
						color: $gray-8;
						transition: all 0.5s ease-in-out;
					}

					.nav-title {
						font-size: 18px;
					}

				}

				.nav-previous {
					position: absolute;
					left: 0;

					a {
						position: relative;
						display: block;
						padding-left: 90px;
						padding-top: 11px;
						padding-bottom: 11px;

						i {
							left: 20px;
						}

						&:before {
							position: absolute;
							content: "";
							top: 50%;
							transform: translateY(-50%);
							left: 0;
							width: 40px;
							height: 100%;
							box-shadow: $box-shadow;
							background-color: $white;
							border-radius: $border-radius;
							transition: all 0.5s ease-in-out;
						}

						&:hover {
							span {
								color: $primary;
							}

							&:before {
								background-color: $primary;
							}

						}

					}

				}

				.nav-next {
					position: absolute;
					right: 0;
					text-align: right;

					a {
						position: relative;
						display: block;
						padding-right: 90px;
						padding-top: 11px;
						padding-bottom: 11px;

						i {
							right: 20px;
						}

						&:before {
							position: absolute;
							content: "";
							top: 50%;
							transform: translateY(-50%);
							right: 0;
							width: 40px;
							height: 100%;
							box-shadow: $box-shadow;
							background-color: $white;
							border-radius: $border-radius;
							transition: all 0.5s ease-in-out;
						}

						&:hover {
							span {
								color: $primary;
							}

							&:before {
								background-color: $primary;
							}

						}

					}

				}

			}

		}

	}

}

.blog-sidebar {
	.widget {
		.widget-title {
			margin-bottom: 20px;
		}

		.search {
			position: relative;

			.form-control {
				background-color: $white;
				color: $gray-8;
				border: 1px solid $white;
				padding-right: 60px;
			}

			i {
				position: absolute;
				right: 0;
				top: 0;
				background: $primary;
				color: $white;
				padding: 17px 16px;
				cursor: pointer;
				border-radius: 0;
			}

		}

		.widget-practice-areas {
			ul {
				li a {
					border-bottom: 1px solid rgba($primary, 0.2);
					padding-bottom: 10px;
					margin-bottom: 10px;
					display: block;
					color: $gray-8;

					&:hover {
						color: $primary;
						border-bottom: 1px solid $primary;
					}

				}

			}

		}

	}

	.recent-posts {
		a {
			font-size: 16px;
			line-height: 24px;
			color: $gray-8;

			&:hover {
				color: $primary;
			}

		}

		.date {
			font-size: 14px;
			color: $primary;
		}

	}

	.social {
		ul {
			margin-bottom: 0;

			li {
				display: inline-block;
				list-style-type: none;
				padding: 0 15px;

				a {
					font-size: 18px;
					color: $gray-8;

					&:hover {
						color: $primary;
					}

				}

				&:last-child {
					padding-right: 0;
				}

				&:first-child {
					padding-left: 0;
				}

			}

		}

	}

	&:last-child {
		margin-bottom: 0;
	}

}

.blog-sidebar .widget .widget-practice-areas ul li.active a {
  color: #c11212;
  border-bottom: 1px solid #c11212;
}

.search .form-control::placeholder {
	color: $body-color !important;
}

.blog-sidebar .widget .tagcloud ul li {
	display: inline-block;
	border: 1px solid $primary;
	padding: 0 12px;
	margin-bottom: 10px;

	&:hover {
		background: $primary;
		border: 1px solid $primary;
	}

	a {
		color: $gray-8;
		line-height: 32px;
	}

}

.blog-sidebar .widget .tagcloud ul li:hover a {
	color: $white;
}

/****************
  Blog Element
****************/
.blog-post-item {
	.blog-post-content {
		text-align: center;
		padding: 0 25px;
		margin-top: 25px;
	}

	.blog-post-thumbnail {
		position: relative;
		transform: translatey(0px);
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.blog-title {
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 0;

		a {
			color: $gray-8;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
		}

	}

	.blog-post-date {
		color: $primary;
		margin-top: 10px;
	}

	&:hover .blog-post-thumbnail {
		transform: translatey(-5px);
		box-shadow: 0 5px 15px rgba($black, 0.1);
	}

	.blog-title a:hover {
		color: $primary;
		text-decoration: none;
	}

}

.blog-post-item {
	margin-bottom: 30px;
}

.owl-carousel .blog-post-item {
	margin-bottom: 0;
}

/* Pagination */
.pagination {
	.page-item {
		padding: 0 5px;

		.page-link {
			padding: 12px 20px;
			color: $white;
			border-radius: 0;
			border-color: $primary;
		}

	}

	.page-link {
		background-color: $primary;
	}

	.page-item.disabled {
		.page-link {
			background-color: $primary;
		}

	}

	.page-item.active {
		.page-link {
			background: $gray-8;
			border-color: $gray-8;
			color: $white;
		}

	}

}

.page-link {
	&:hover {
		background: $gray-8;
		border-color: $gray-8;
		color: $white;
	}

}

.blog-author {
	img {
		height: auto;
		width: 150px;
	}

}
