/****************
  Footer
****************/
.footer{
	h5{
		margin-bottom: 30px;
	}
	.footer-contact-info{
		ul{
			padding-top: 15px;
			background-position: top center;
    		background-repeat: no-repeat;
    		width: 100%;
			li{
				display: flex;
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
				}
				i{
				  font-size: 18px;
				  line-height: 1.5;
				  color:  $primary;
				}
				span{
					padding-left: 15px;
					align-self: top;
				}
			}
		}
	}
	.footer-link{
		ul{
			float: left;
			li{
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
				a{
					color: $body-color;
					&:hover{
						color:  $primary;
					}
				}
			}
		}
	}
	.footer-services-List{
		ul{
			li{
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
				a{
					color: $white;
					&:hover{
						color:  $primary;
					}
				}
			}
		}
	}
	.footer-services-List{
		ul{
			li{
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
				a{
					color: $white;
					&:hover{
						color:  $primary;
					}
				}
			}
		}
	}
	.footer-recent-List{
		ul{
			li{
				margin-bottom: 20px;
			}
			.footer-recent-list-item{
				display: flex;
				align-items: center;
				img{
					width: 80px;
				}
				.footer-recent-list-item-info{
					margin-left: 15px;
					.date{
						font-size: 12px;
						color: $white;
					}
					.title{
						font-weight:700;
					}
				}
			}
		}
	}
	.footer-subscribe{
		form{
			.form-group{
				.form-control{
					background:rgba($white,0.2);
					border:1px solid rgba($white,0.02);
					color:$white;
				}
				.form-control:-moz-placeholder {
					color: $white;
				}
				.form-control:-ms-input-placeholder {
					color: $white;
				}
				.form-control:-webkit-input-placeholder {
					color: $white;
				}
			}
			.btn.btn-white{
				&:hover{
					background-color: $primary;
					border-color: $primary;
				}
			}
		}
	}
	.footer-bottom {
		margin-top: 50px;
		padding: 40px 0;
		.copyright{
			p{
				font-size: 14px;
			}
		}
		ul {
			li {
				a {
					color: $gray-8;
					&:hover {
						color: $primary;
					}
				}
			}
		}
	}
}

/* footer-01 */

.footer.footer-01 {
	padding-top: 180px;
}
.footer-top {
	.social-icons {
		li {
			padding-right: 40px;
			&:last-child {
				padding-right: 0;
			}
		}
	}
}
