/****************
  Error 404
****************/
.error {
	display: flex;
	align-items: center;
	justify-content: center;

	h1 {
		font-size: 208px;
	}

}
