/****************
  counter
****************/
.counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .counter-icon {
    color: $primary;
    font-size: 70px;
    line-height: 70px;
  }

  .counter-content label {
    display: block;
    color: $white;
    margin-bottom: 0;
  }

  &.counter-style-01 {
    .counter-icon {
      color: $primary;
      margin-right: 25px;
    }

    .counter-content .timer {
      color: $gray-8;
    }

  }

  .counter-content .timer {
    position: relative;
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    color: $white;
    margin-bottom: 10px;
    display: block;
  }

  &.counter-style-01 .counter-content label {
    color: $primary;
    font-weight: bold;
  }

}

.dark-bg {
  background: $gray-8;
}

.counter.counter-style-02 {
  display: block;
  text-align: center;

  .counter-content {
    label {
      color: $primary;
    }

    .timer {
      margin-top: 20px;
    }

  }

}

.counter.counter-style-03 {
  padding: 10px 0px 20px 0;

  .counter-content {
    display: flex;
    min-width: 110px;

    span {
      color: $primary;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 0;
      font-weight: 600;
    }

  }

  label {
    color: $gray-8;
    font-size: 18px;
    margin-bottom: 0;
    align-self: flex-end;
  }

}

/* counter border style */
.counter-border-style {
  li {
    &:nth-child(1), &:nth-child(2) {
      border-bottom: 1px solid rgba($white, 0.15);
    }

    &:nth-child(1), &:nth-child(3) {
      border-right: 1px solid rgba($white, 0.15);
    }

    transition: all 0.5s ease-in-out;

    &:hover {
      border: 1px solid $primary !important;
    }

    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    cursor: pointer;
    padding: 30px 20px;
    list-style: none;
    border: 1px solid transparent;
  }

  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
