/****************
  Helper Classes
****************/

/* color */
.text-primary {
  color: $primary !important;
}

.text-white {
  color: $white;
}

.text-dark {
  color: $gray-8 !important;
}

.text-light {
  color: $gray-9 !important;
}

.text-muted {
  color: $gray-3 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: $gray-8 !important;
}

/* hr */
hr {
  border-top-color: $border-color;
}

/* background */
.bg-primary {
  background-color: $primary !important;
}

.bg-dark {
  background-color: $gray-8 !important;
}

.bg-light {
  background-color: $gray-9 !important;
}

.bg-black {
  background-color: $black !important;
}

/* fill */
.fill-primary {
  fill: $primary;
  fill-rule: evenodd;
}

.fill-dark {
  fill: $gray-8;
  fill-rule: evenodd;
}

.fill-gray {
  fill: $gray-2;
  fill-rule: evenodd;
}

.fill-light {
  fill: $gray-1;
  fill-rule: evenodd;
}

.fill-white {
  fill: $white;
  fill-rule: evenodd;
}

/* Opacity */
.opacity-05 {
  opacity: 0.05;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

/* position */
.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

/* Page section margin padding */
.space-ptb {
  padding: 100px 0;
}

.space-pt {
  padding-top: 100px;
}

.space-pb {
  padding-bottom: 100px;
}

.space-sm-ptb {
  padding: 70px 0;
}

.space-sm-pt {
  padding-top: 70px;
}

.space-sm-pb {
  padding-bottom: 70px;
}

.section-pb {
  padding-bottom: 200px;
}

.space-lg-mt {
  margin-top: 150px;
}

/* Height */
.h-100vh {
  height: 100vh !important;
}

.h-900 {
  height: 900px;
}

.h-800 {
  height: 800px;
}

.h-700 {
  height: 700px;
}

.h-600 {
  height: 600px;
}

.h-500 {
  height: 500px;
}

.h-400 {
  height: 400px;
}

.h-300 {
  height: 300px;
}

/*****************************
  Responsive
*****************************/
@media (max-width:1199px) {

  /* Height */
  .h-lg-800 {
    height: 800px;
  }

  .h-lg-700 {
    height: 700px;
  }

  .h-lg-600 {
    height: 600px;
  }

  .h-lg-500 {
    height: 500px;
  }

  .h-lg-400 {
    height: 400px;
  }

  .h-lg-300 {
    height: 300px;
  }

}

@media (max-width:991px) {

  /* Height */
  .h-md-800 {
    height: 800px;
  }

  .h-md-700 {
    height: 700px;
  }

  .h-md-600 {
    height: 600px;
  }

  .h-md-500 {
    height: 500px;
  }

  .h-md-400 {
    height: 400px;
  }

  .h-md-300 {
    height: 300px;
  }

  .h-md-auto {
    height: auto;
  }

}

@media (max-width:767px) {

  /* Height */
  .h-sm-600 {
    height: 600px;
  }

  .h-sm-500 {
    height: 500px;
  }

  .h-sm-400 {
    height: 400px;
  }

  .h-sm-300 {
    height: 300px;
  }

  .h-sm-auto {
    height: auto;
  }

}

/* padding */
.p-6 {
  padding: 5rem !important;
}

.px-6 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-6 {
  padding-bottom: 5rem !important;
  padding-top: 5rem !important;
}

.pt-6 {
  padding-top: 5rem !important;
}

.pb-6 {
  padding-bottom: 5rem !important;
}

.pl-6 {
  padding-left: 5rem !important;
}

.pr-6 {
  padding-right: 5rem !important;
}

.p-7 {
  padding: 7rem !important;
}

.px-7 {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.py-7 {
  padding-bottom: 7rem !important;
  padding-top: 7rem !important;
}

.pt-7 {
  padding-top: 7rem !important;
}

.pb-7 {
  padding-bottom: 7rem !important;
}

.pl-7 {
  padding-left: 7rem !important;
}

.pr-7 {
  padding-right: 7rem !important;
}

.p-8 {
  padding: 9rem !important;
}

.px-8 {
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.py-8 {
  padding-bottom: 9rem !important;
  padding-top: 9rem !important;
}

.pt-8 {
  padding-top: 9rem !important;
}

.pb-8 {
  padding-bottom: 9rem !important;
}

.pl-8 {
  padding-left: 9rem !important;
}

.pr-8 {
  padding-right: 9rem !important;
}

.half-section-plr {
  padding: 0px 100px 0px 250px;
}

.half-section-prl {
  padding: 0px 250px 0px 100px;
}

/* margin */
.m-n6 {
  margin: -7rem !important;
}

.mt-n6, .my-n6 {
  margin-top: -7rem !important;
}

.mr-n6, .mx-n6 {
  margin-right: -7rem !important;
}

.mb-n6, .my-n6 {
  margin-bottom: -7rem !important;
}

.ml-n6, .mx-n6 {
  margin-left: -7rem !important;
}

.mt-n7, .my-n7 {
  margin-top: -9rem !important;
}

.mr-n7, .mx-n7 {
  margin-right: -9rem !important;
}

.mb-n7, .my-n7 {
  margin-bottom: -9rem !important;
}

.ml-n7, .mx-n7 {
  margin-left: -9rem !important;
}

.m-n8 {
  margin: -15rem !important;
}

.mt-n8, .my-n8 {
  margin-top: -15rem !important;
}

.mr-n8, .mx-n8 {
  margin-right: -15rem !important;
}

.mb-n8, .my-n8 {
  margin-bottom: -15rem !important;
}

.ml-n8, .mx-n8 {
  margin-left: -15rem !important;
}

.m-n8 {
  margin: -15rem !important;
}

.mt-n9, .my-n9 {
  margin-top: -21rem !important;
}

.mr-n9, .mx-n9 {
  margin-right: -21rem !important;
}

.mb-n9, .my-n9 {
  margin-bottom: -21rem !important;
}

.ml-n9, .mx-n9 {
  margin-left: -21rem !important;
}

@media (min-width:576px) {
  .m-sm-n6 {
    margin: -7rem !important;
  }

  .mt-sm-n6, .my-sm-n6 {
    margin-top: -7rem !important;
  }

  .mr-sm-n6, .mx-sm-n6 {
    margin-right: -7rem !important;
  }

  .mb-sm-n6, .my-sm-n6 {
    margin-bottom: -7rem !important;
  }

  .ml-sm-n6, .mx-sm-n6 {
    margin-left: -7rem !important;
  }

  .m-sm-n7 {
    margin: -9rem !important;
  }

  .mt-sm-n7, .my-sm-n7 {
    margin-top: -9rem !important;
  }

  .mr-sm-n7, .mx-sm-n7 {
    margin-right: -9rem !important;
  }

  .mb-sm-n7, .my-sm-n7 {
    margin-bottom: -9rem !important;
  }

  .ml-sm-n7, .mx-sm-n7 {
    margin-left: -9rem !important;
  }

  .p-sm-6 {
    padding: 5rem !important;
  }

  .px-sm-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-sm-6 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }

  .pt-sm-6 {
    padding-top: 5rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-6 {
    padding-left: 5rem !important;
  }

  .pr-sm-6 {
    padding-right: 5rem !important;
  }

  .p-sm-7 {
    padding: 7rem !important;
  }

  .px-sm-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-sm-7 {
    padding-bottom: 7rem !important;
    padding-top: 7rem !important;
  }

  .pt-sm-7 {
    padding-top: 7rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 7rem !important;
  }

  .pl-sm-7 {
    padding-left: 7rem !important;
  }

  .pr-sm-7 {
    padding-right: 7rem !important;
  }

  .p-sm-8 {
    padding: 9rem !important;
  }

  .px-sm-8 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-sm-8 {
    padding-bottom: 9rem !important;
    padding-top: 9rem !important;
  }

  .pt-sm-8 {
    padding-top: 9rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 9rem !important;
  }

  .pl-sm-8 {
    padding-left: 9rem !important;
  }

  .pr-sm-8 {
    padding-right: 9rem !important;
  }

}

@media (min-width:768px) {
  .m-md-n6 {
    margin: -7rem !important;
  }

  .mt-md-n6, .my-md-n6 {
    margin-top: -7rem !important;
  }

  .mr-md-n6, .mx-md-n6 {
    margin-right: -7rem !important;
  }

  .mb-md-n6, .my-md-n6 {
    margin-bottom: -7rem !important;
  }

  .ml-md-n6, .mx-md-n6 {
    margin-left: -7rem !important;
  }

  .m-md-n7 {
    margin: -9rem !important;
  }

  .mt-md-n7, .my-md-n7 {
    margin-top: -9rem !important;
  }

  .mr-md-n7, .mx-md-n7 {
    margin-right: -9rem !important;
  }

  .mb-md-n7, .my-md-n7 {
    margin-bottom: -9rem !important;
  }

  .ml-md-n7, .mx-md-n7 {
    margin-left: -9rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .px-md-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-md-6 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }

  .pt-md-6 {
    padding-top: 5rem !important;
  }

  .pb-md-6 {
    padding-bottom: 5rem !important;
  }

  .pl-md-6 {
    padding-left: 5rem !important;
  }

  .pr-md-6 {
    padding-right: 5rem !important;
  }

  .p-md-7 {
    padding: 7rem !important;
  }

  .px-md-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-md-7 {
    padding-bottom: 7rem !important;
    padding-top: 7rem !important;
  }

  .pt-md-7 {
    padding-top: 7rem !important;
  }

  .pb-md-7 {
    padding-bottom: 7rem !important;
  }

  .pl-md-7 {
    padding-left: 7rem !important;
  }

  .pr-md-7 {
    padding-right: 7rem !important;
  }

  .p-md-8 {
    padding: 9rem !important;
  }

  .px-md-8 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-md-8 {
    padding-bottom: 9rem !important;
    padding-top: 9rem !important;
  }

  .pt-md-8 {
    padding-top: 9rem !important;
  }

  .pb-md-8 {
    padding-bottom: 9rem !important;
  }

  .pl-md-8 {
    padding-left: 9rem !important;
  }

  .pr-md-8 {
    padding-right: 9rem !important;
  }

}

@media (min-width:992px) {
  .m-lg-n6 {
    margin: -7rem !important;
  }

  .mt-lg-n6, .my-lg-n6 {
    margin-top: -7rem !important;
  }

  .mr-lg-n6, .mx-lg-n6 {
    margin-right: -7rem !important;
  }

  .mb-lg-n6, .my-lg-n6 {
    margin-bottom: -7rem !important;
  }

  .ml-lg-n6, .mx-lg-n6 {
    margin-left: -7rem !important;
  }

  .m-lg-n7 {
    margin: -9rem !important;
  }

  .mt-lg-n7, .my-lg-n7 {
    margin-top: -9rem !important;
  }

  .mr-lg-n7, .mx-lg-n7 {
    margin-right: -9rem !important;
  }

  .mb-lg-n7, .my-lg-n7 {
    margin-bottom: -9rem !important;
  }

  .ml-lg-n7, .mx-lg-n7 {
    margin-left: -9rem !important;
  }

  .p-lg-6 {
    padding: 5rem !important;
  }

  .px-lg-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-lg-6 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }

  .pt-lg-6 {
    padding-top: 5rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-6 {
    padding-left: 5rem !important;
  }

  .pr-lg-6 {
    padding-right: 5rem !important;
  }

  .p-lg-7 {
    padding: 7rem !important;
  }

  .px-lg-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-lg-7 {
    padding-bottom: 7rem !important;
    padding-top: 7rem !important;
  }

  .pt-lg-7 {
    padding-top: 7rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 7rem !important;
  }

  .pl-lg-7 {
    padding-left: 7rem !important;
  }

  .pr-lg-7 {
    padding-right: 7rem !important;
  }

  .p-lg-8 {
    padding: 9rem !important;
  }

  .px-lg-8 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-lg-8 {
    padding-bottom: 9rem !important;
    padding-top: 9rem !important;
  }

  .pt-lg-8 {
    padding-top: 9rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 9rem !important;
  }

  .pl-lg-8 {
    padding-left: 9rem !important;
  }

  .pr-lg-8 {
    padding-right: 9rem !important;
  }

}

@media (min-width:1200px) {
  .m-xl-n6 {
    margin: -7rem !important;
  }

  .mt-xl-n6, .my-xl-n6 {
    margin-top: -7rem !important;
  }

  .mr-xl-n6, .mx-xl-n6 {
    margin-right: -7rem !important;
  }

  .mb-xl-n6, .my-xl-n6 {
    margin-bottom: -7rem !important;
  }

  .ml-xl-n6, .mx-xl-n6 {
    margin-left: -7rem !important;
  }

  .m-xl-n7 {
    margin: -9rem !important;
  }

  .mt-xl-n7, .my-xl-n7 {
    margin-top: -9rem !important;
  }

  .mr-xl-n7, .mx-xl-n7 {
    margin-right: -9rem !important;
  }

  .mb-xl-n7, .my-xl-n7 {
    margin-bottom: -9rem !important;
  }

  .ml-xl-n7, .mx-xl-n7 {
    margin-left: -9rem !important;
  }

  .p-xl-6 {
    padding: 5rem !important;
  }

  .px-xl-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-xl-6 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }

  .pt-xl-6 {
    padding-top: 5rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-6 {
    padding-left: 5rem !important;
  }

  .pr-xl-6 {
    padding-right: 5rem !important;
  }

  .p-xl-7 {
    padding: 7rem !important;
  }

  .px-xl-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-xl-7 {
    padding-bottom: 7rem !important;
    padding-top: 7rem !important;
  }

  .pt-xl-7 {
    padding-top: 7rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 7rem !important;
  }

  .pl-xl-7 {
    padding-left: 7rem !important;
  }

  .pr-xl-7 {
    padding-right: 7rem !important;
  }

  .p-xl-8 {
    padding: 9rem !important;
  }

  .px-xl-8 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-xl-8 {
    padding-bottom: 9rem !important;
    padding-top: 9rem !important;
  }

  .pt-xl-8 {
    padding-top: 9rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 9rem !important;
  }

  .pl-xl-8 {
    padding-left: 9rem !important;
  }

  .pr-xl-8 {
    padding-right: 9rem !important;
  }

}

/* pre-loader */
#pre-loader {
  background-color: $white;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;
}

#pre-loader img {
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 99;
  margin: 0 auto;
}

/* img holder */
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-holder-right {
  background-position: center right;
  background-repeat: no-repeat;
  width: 100%;
}

/* Background overlay */
.bg-overlay-dark-10 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-dark-20 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-dark-30 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-dark-40 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-dark-50 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-dark-60 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-dark-70 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-dark-80 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-dark-90 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($gray-8, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-10 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-20 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-30 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-40 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-50 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-60 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-70 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-80 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-white-90 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($white, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-theme-20 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($primary, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-theme-50 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($primary, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-theme-70 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($primary, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

.bg-overlay-theme-90 {
  position: relative;
  z-index: 1;

  &:before {
    background: rgba($primary, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

/* avatar */
.avatar {
  width: 50px;
  height: 50px;
  display: inline-block;
}

.avatar.avatar-sm {
  width: 20px;
  height: 20px;
}

.avatar.avatar-md {
  width: 80px;
  height: 80px;
}

.avatar.avatar-lg {
  width: 145px;
  height: 145px;
}

/* Font Size */
.display-1 {
  font-size: 9rem;
  line-height: 1;
  font-weight: 800;
}

.display-2 {
  font-size: 8rem;
  line-height: 1.2;
  font-weight: 800;
}

.display-3 {
  font-size: 7rem;
  line-height: 1.2;
  font-weight: 800;
}

.display-4 {
  font-size: 6rem;
  line-height: 1.2;
  font-weight: 800;
}

.display-5 {
  font-size: 5rem;
  line-height: 1.2;
}

.display-6 {
  font-size: 4rem;
  line-height: 1.2;
}

.display-7 {
  font-size: 3rem;
  line-height: 1.2;
}

.display-8 {
  font-size: 2.5rem;
  line-height: 1.2;
}

.display-9 {
  font-size: 1.5rem;
  line-height: 1.4;
}

.display-10 {
  font-size: 1rem;
  line-height: 1.4;
}

/*****************************
  Responsive
*****************************/
@media (max-width:1199px) {

  /* Font Size */
  .display-1 {
    font-size: 8rem;
  }

  .display-2 {
    font-size: 7rem;
  }

  .display-3 {
    font-size: 6rem;
  }

  .display-4 {
    font-size: 5rem;
  }

  .display-5 {
    font-size: 4.5rem;
  }

  .display-6 {
    font-size: 4rem;
  }

  .display-7 {
    font-size: 2.5rem;
  }

  .display-8 {
    font-size: 2rem;
  }

}

@media (max-width:991px) {

  /* Font Size */
  .display-1 {
    font-size: 7rem;
  }

  .display-2 {
    font-size: 6rem;
  }

  .display-3 {
    font-size: 5rem;
  }

  .display-4 {
    font-size: 4.5rem;
  }

  .display-5 {
    font-size: 4rem;
  }

  .display-6 {
    font-size: 3rem;
  }

}

@media (max-width:767px) {

  /* Font Size */
  .display-1 {
    font-size: 6rem;
  }

  .display-2 {
    font-size: 5rem;
  }

  .display-3 {
    font-size: 4rem;
  }

  .display-4 {
    font-size: 3.5rem;
  }

  .display-5 {
    font-size: 3rem;
  }

  .display-6 {
    font-size: 2.5rem;
  }

  .display-7 {
    font-size: 2.3rem;
  }

  .display-9 {
    font-size: 1.3rem;
  }

}

@media (max-width:575px) {

  /* Font Size */
  .display-1 {
    font-size: 5rem;
  }

  .display-2 {
    font-size: 4rem;
  }

  .display-3 {
    font-size: 3rem;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .display-5 {
    font-size: 2rem;
  }

  .display-6 {
    font-size: 1.8rem;
  }

  .display-7 {
    font-size: 1.6rem;
  }

  .display-8 {
    font-size: 1.5rem;
  }

  .display-9 {
    font-size: 1.2rem;
  }

}

// border-radius
.b-radius {
  border-radius: $border-radius;
}

.b-radius-none {
  border-radius: 0 !important;
}

.b-radius-left-none {
  border-radius: 0 3px 3px 0;
}

.b-radius-right-none {
  border-radius: 3px 0 0 3px;
}

/* z index */
.z-index-none {
  z-index: inherit;
}

.z-index-1 {
  z-index: 1;
}

.z-index-9 {
  z-index: 9;
}

.z-index-99 {
  z-index: 99;
}

.z-index-999 {
  z-index: 999;
}

.z-index-n1 {
  z-index: -1;
}

.w-space {
  white-space: nowrap;
}

.grayscale, .img-grayscale {
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}

.grayscale:hover {
  filter: inherit;
}

/*************************
       Responsive
*************************/
@media (max-width:991px) {
  .mb-md-5 {
    margin-bottom: 1.5rem !important;
  }

  .bg-md-none {
    background-image: none !important;
  }

}

@media (max-width:767px) {
  .bg-sm-none {
    background-image: none !important;
  }

  .p-6 {
    padding: 3rem !important;
  }

  .pt-6 {
    padding-top: 3rem !important;
  }

  .pb-6 {
    padding-bottom: 3rem !important;
  }

  .pl-6 {
    padding-left: 3rem !important;
  }

  .pr-6 {
    padding-right: 3rem !important;
  }

}
