/****************
  list style
****************/
.list {
  padding-left: 0;

  li {
    padding-bottom: 15px;
    color: $gray-3;
    list-style-type: none;
    display: flex;
    font-weight: 600;
    align-items: baseline;
  }

  i {
    color: $primary;
    margin-right: 20px;
  }

}

.list li:last-child {
  padding-bottom: 0;
}

/* List Dark */
.list.list-dark {
  li {
    color: $gray-8;
  }

  i {
    color: $primary;
  }

}

/* list light */
.list.list-light {
  li {
    color: $white;
  }

  i {
    color: $white;
  }

}

.list.list-style-02 i, .list.list-style-02 span {
  color: $gray-8;
  font-size: 16px;
}

.list-style-02 p {
  color: $gray-9;
  font-weight: normal;
}

.list.list-style-02 span {
  margin-bottom: 10px;
  display: flex;
}
