/****************
  Skill
****************/
.round-skill {
  display: flex;
}

.chart-title {
  align-self: center;
  padding-left: 15px;
}

.round-chart {
  display: inline-block;
  position: relative;
  text-align: center;

  span, i {
    display: inline-block;
    font-size: 28px;
    z-index: 2;
    font-weight: 600;
    position: absolute;
  }

  .percent:after {
    content: '%';
  }

}

/* progress */
.progress {
  position: relative;
  overflow: inherit;
  height: 10px;
  margin: 40px 0px 15px;
  width: 100%;
  display: inline-block;
  border-radius: 5px;
  background-color: $gray-8;

  .progress-bar {
    height: 10px;
    border-radius: 50px;
  }

  .progress-bar-striped {
    background-image: none;
  }

  .progress-bar-title {
    position: absolute;
    left: 0;
    top: -35px;
    color: $gray-8;
    font-size: 14px;
    font-weight: 600;
  }

  .progress-bar-number {
    position: absolute;
    right: 0;
    color: $gray-8;
    top: -35px;
    font-weight: 600;
  }

}
