/*************************
       1700px
*************************/
@media (min-width:1200px) {

	/* container */
	.container {
		max-width: 1170px;
	}

}

@media (max-width:1400px) {
	.space-px {
		padding-left: 80px;
		padding-right: 80px;
	}

	.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
		right: 25px;
	}

	.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
		left: 25px;
	}

	.header .search-btn a {
		margin-left: 0;
	}

	.half-section-plr {
		padding: 0px 70px 0px 150px;
	}

	.half-section-prl {
		padding: 0px 150px 0px 70px;
	}

	.header.default .navbar .navbar-nav .nav-link, .header.header-02 .navbar .navbar-nav .nav-item .nav-link {
		padding: 10px 10px;
	}

	.header.default .navbar .navbar-nav .nav-item .nav-link span, .header.header-02 .navbar .navbar-nav .nav-item .nav-link span {
		padding-left: 14px;
	}

}

@media (max-width:1199px) {

	/* Page section margin padding */
	.space-ptb {
		padding: 70px 0;
	}

	.space-pt {
		padding-top: 70px;
	}

	.space-pb {
		padding-bottom: 70px;
	}

	.space-sm-ptb {
		padding: 50px 0;
	}

	.space-sm-pt {
		padding-top: 50px;
	}

	.space-sm-pb {
		padding-bottom: 50px;
	}

	.space-lg-mt {
		margin-top: 100px;
	}

	.space-lg-pt {
		padding-top: 100px;
	}

	.header .container-fluid {
		padding: 0px 30px;
	}

	.header.default .navbar .navbar-nav .nav-link, .header.default.is-sticky .navbar .navbar-nav .nav-item .nav-link, .header.header-02 .navbar .navbar-nav .nav-item .nav-link, .header.header-02.is-sticky .navbar .navbar-nav .nav-item .nav-link, .header.header-03 .navbar .navbar-nav .nav-item .nav-link, .header.header-03.is-sticky .navbar .navbar-nav .nav-item .nav-link {
		padding: 10px 7px;
		font-size: 13px;
	}

	.header.default .navbar .navbar-nav .nav-item .nav-link span, .header.header-02 .navbar .navbar-nav .nav-item .nav-link span {
		padding-left: 14px;
	}

	.header .navbar .navbar-brand img {
		height: 50px;
	}

	/* blog */
	.blog-post .blog-post-content {
		text-align: center;
		padding: 25px 10px 40px;
		margin: -45px 15px 0;
	}

	.blog-detail .blog-post-navigation .post-navigation .nav-link .nav-previous a {
		padding-left: 70px;
	}

	.blog-detail .blog-post-navigation .post-navigation .nav-link .nav-next a {
		padding-right: 70px;
	}

	.half-section-plr {
		padding: 0px 40px 0px 40px;
	}

	.half-section-prl {
		padding: 0px 20px 0px 40px;
	}

	/* btn */
	.btn {
		font-size: 13px;
	}

	/* Team */
	.team .team-overlay .team-contant p {
		display: none;
	}

}

@media (max-width:991px) {
	.space-ptb {
		padding: 60px 0;
	}

	.space-pt {
		padding-top: 60px;
	}

	.space-pb {
		padding-bottom: 60px;
	}

	.space-lg-mt {
		margin-top: 0px;
	}

	.space-lg-pt {
		padding-top: 0px;
	}

	.banner.h-700 {
		height: 100%;
	}

	/* Header */
	.navbar-nav {
		padding: 10px 0;
	}

	.header .search {
		margin-right: 40px;
	}

	.header .search .search-box {
		right: 70px;
	}

	.header .add-listing {
		display: none;
	}

	.header .search-btn a {
		margin-right: 40px;
		font-size: 16px;
	}

	.navbar-brand .logo {
		display: none;
	}

	.navbar-brand .logo-sticky {
		display: inline-block;
	}

	.header.default .navbar {
		position: relative;
		background: $white;
	}

	.navbar-toggler {
		border: none;
		position: absolute;
		height: 75px;
		right: 20px;
	}

	.header .navbar-collapse {
		position: absolute;
		top: 100%;
		z-index: 999;
		background: $white;
		width: 100%;
		max-height: 350px;
		overflow: auto;
		left: 0;
		margin-left: 0px;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		box-shadow: 0px 5px 10px rgba($black, 0.1);
	}

	.header.default .navbar .navbar-nav .nav-link, .header.default.is-sticky .navbar .navbar-nav .nav-item .nav-link, .header.header-02 .navbar .navbar-nav .nav-item .nav-link, .header.header-02.is-sticky .navbar .navbar-nav .nav-item .nav-link, .header.header-03 .navbar .navbar-nav .nav-item .nav-link, .header.header-03.is-sticky .navbar .navbar-nav .nav-item .nav-link {
		color: $gray-8;
		padding: 10px 20px;
		font-size: 13px;
	}

	.header.default .navbar .navbar-nav .nav-item.active .nav-link, .header.default .navbar .navbar-nav .nav-item .nav-link:hover, .header.header-02 .navbar .navbar-nav .nav-item.active .nav-link, .header.header-02 .navbar .navbar-nav .nav-item .nav-link:hover, .header.header-03 .navbar .navbar-nav .nav-item.active .nav-link, .header.header-03 .navbar .navbar-nav .nav-item .nav-link:hover {
		color: $gray-8;
	}

	.header.default .navbar .navbar-nav .nav-item.active .nav-link {
		color: $gray-8;
	}

	.header.default .navbar .navbar-nav .nav-item .nav-link:hover:before, .header.default .navbar .navbar-nav .nav-item.active .nav-link:before {
		right: calc(100% - 35px);
	}

	.header.default .navbar .navbar-nav .nav-item .nav-link span, .header.header-02 .navbar .navbar-nav .nav-item .nav-link span, .header.header-03 .navbar .navbar-nav .nav-item .nav-link span {
		display: none;
	}

	.header .search .search-btn {
		color: $gray-8;
	}

	.header .navbar .dropdown-menu {
		margin: 0px;
		font-size: 14px;
		border-radius: 0px;
		border: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		background: $gray-9;
		padding: 14px 30px;
		font-size: 13px;
	}

	.navbar .navbar-nav .nav-link i {
		position: absolute;
		right: 20px;
	}

	.header.header-02 .topbar {
		display: none;
	}

	.header.header-02 .navbar .navbar-brand, .header.header-02 .navbar .add-listing {
		display: block;
	}

	.header.header-02 .navbar .add-listing {
		flex: 0 0 220px;
	}

	.header-inner {
		padding: 120px 0;
	}

	.header-inner .ineer-banner-title {
		font-size: 24px;
		bottom: -100px;
	}

	.header.header-02 .navbar .navbar-nav .nav-item .nav-link:hover:before, .header.header-02 .navbar .navbar-nav .nav-item.active .nav-link:before {
		right: calc(100% - 35px);
	}

	.header.header-02 .navbar {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.header.header-02.is-sticky .navbar-brand img.logo-dark {
    display: block;
	}
	.header.header-02.is-sticky .navbar-brand img {
	    display: none;
	}
	.header.header-02 .navbar-brand img.logo-dark {
	    display: none;
	}


	/* section-title */
	.section-title {
		margin-bottom: 40px;
	}

	.section-title .title {
		margin-bottom: 20px;
	}

	.section-title h6 {
		margin-bottom: 20px;
	}

	.slider-01 h1 {
		font-size: 48px;
	}

	/* About */
	.about-section-tittle {
		background-position: center;
	}

	/* footer */
	.footer.footer-01 {
		padding-top: 180px;
	}

	/* testimonial */
	.testimonial .testimonial-info .testimonial-content {
		font-size: 20px;
	}

	.owl-carousel .owl-nav .owl-prev {
		left: 0;
	}

	.owl-carousel .owl-nav .owl-next {
		right: 0;
	}

	.bg-overlay-right:before {
		display: none;
	}

}

@media only screen and (min-width:768px) and (max-width:991px) {
	.container {
		max-width: 96%;
	}

}

@media (max-width:767px) {
	h1 {
		font-size: 32px;
	}

	h2 {
		font-size: 28px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 17px;
	}

	.space-ptb {
		padding: 50px 0;
	}

	.space-pt {
		padding-top: 50px;
	}

	.space-pb {
		padding-bottom: 50px;
	}

	.space-sm-ptb {
		padding: 30px 0;
	}

	.space-sm-pt {
		padding-top: 30px;
	}

	.space-sm-pb {
		padding-bottom: 30px;
	}

	.container {
		max-width: 100%;
	}

	.section-pb {
		padding-bottom: 50px;
	}

	/* padding */
	.p-6 {
		padding: 3rem !important;
	}

	.pt-6 {
		padding-top: 3rem !important;
	}

	.pb-6 {
		padding-bottom: 3rem !important;
	}

	.pl-6 {
		padding-left: 3rem !important;
	}

	.pr-6 {
		padding-right: 3rem !important;
	}

	/* accordion */
	.accordion .accordion-icon.card-header button {
		padding: 15px 30px 15px 80px;
	}

	.accordion .card .card-body {
		padding: 10px 30px 0px 80px;
	}

	.list {
		margin-bottom: 12px;
	}

	/* Case Studies */
	.my-shuffle-container .grid-item {
		width: 50%;
	}

	.swiper-button-next, .swiper-button-prev {
		display: none;
	}

	.blog-detail .blog-post-navigation .post-navigation .nav-link .nav-previous {
		position: inherit;
		margin-bottom: 30px;
	}

	.header.header-02 .navbar .add-listing {
		flex: 0 0 240px;
	}

	.header-inner {
		padding: 80px 0;
	}

	.header-inner .ineer-banner-title {
		font-size: 20px;
		bottom: -100px;
		padding: 20px 30px;
	}

	/* Coming Soon */
	.coming-soon {
		height: 100% !important;
		padding-bottom: 50px !important;
		padding-top: 100px !important;
	}

	.coming-soon-content {
		padding: 100px 50px 50px;
	}

}

@media (max-width:575px) {
	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 26px;
	}

	h3 {
		font-size: 24px;
	}

	.section-title {
		margin-bottom: 30px;
	}

	.header .search .search-box {
		right: 0;
		margin: 0 15px;
		left: 0;
		width: auto;
	}

	.header.header-02 .navbar .add-listing, .header .add-listing, .header.header-02.is-sticky .navbar .add-listing {
		display: none;
	}

	.header .navbar .navbar-brand {
		flex: 0 0 130px;
		padding: 15px 0px;
	}

	/* banner */
	.header-inner {
		margin-bottom: 40px;
	}

	.frame-content h1 {
		font-size: 48px;
		line-height: 48px;
	}

	/* testimonial */
	.testimonial-quote i {
		font-size: 60px;
	}

	.testimonial .testimonial-info .testimonial-content, .testimonial-02 .testimonial-info .testimonial-content {
		margin-bottom: 30px;
	}

	.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
		display: none;
	}

	/* accordion */
	.accordion .card .card-header button {
		padding: 10px 30px 10px 55px;
	}

	.accordion .card .card-header button:before {
		left: 0px;
		width: 40px;
		height: 40px;
		line-height: 40px;
	}

	.accordion .card .card-body {
		padding: 10px 30px 0px 55px;
	}

	.header .container-fluid {
		padding: 0 15px;
	}

	.navbar-toggler {
		right: 10px;
	}

	.countdown span {
		font-size: 28px;
	}

	.countdown {
		margin-right: 10px;
		padding-right: 10px;
	}

	.countdown p {
		font-size: 14px;
	}

	/* History */
	.timeline-item .timeline {
		li {
			display: block;
			position: relative;
			margin-bottom: 30px;
			padding-left: 35px;

			&:before {
				content: "";
				width: 13px;
				height: 13px;
				position: absolute;
				top: 15px;
				left: 0px;
				background: $primary;
				border-radius: 50px;
			}

			&:last-child {
				margin-bottom: 0;
			}

		}

		&:before {
			top: 17px;
			left: 6px;
		}

		li {
			.timeline-content {
				margin-top: 15px;
			}

			.timeline-title:before {
				content: normal;
			}

			.timeline-year {
				margin-right: 0;
				min-width: auto;
				min-height: auto;
				padding: 7px;
				font-size: 14px;
			}

		}

	}

	.counter-border-style li {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.counter-border-style li:nth-child(1), .counter-border-style li:nth-child(3) {
		border-bottom: 1px solid rgba($white, 0.15);
		border-right: inherit;
	}

	/* video */
	.video-btn i, .video-btn:before, .call-btn i, .call-btn:before {
		width: 60px;
		height: 60px;
	}

	.border-lr:before {
		left: 15px;
		width: 38%;
	}

	.border-lr:after {
		right: 15px;
		width: 38%;
	}

}

@media (max-width:479px) {

	/* banner */
	.banner h1 {
		font-size: 30px;
	}

	.frame-content h1 {
		font-size: 30px;
		line-height: 30px;
	}

	/* testimonial */
	.avatar.avatar-lg {
		width: 80px;
		height: 80px;
	}

	.testimonial .testimonial-info .testimonial-content {
		font-size: 18px;
	}

	.owl-carousel .owl-nav i {
		width: 50px;
		height: 100px;
		line-height: 100px;
	}

	/* Case Studies */
	.my-shuffle-container .grid-item {
		width: 100%;
	}

	/* feature-info */
	.feature-info .feature-info-icon i {
		font-size: 48px;
	}

	.feature-info .feature-info-content .feature-info-title {
		margin-bottom: 10px;
	}

	.social-icon ul li a {
		height: 30px;
		width: 30px;
		line-height: 30px;
		margin-right: 10px;
	}

	.social-icon ul li a i {
		font-size: 14px;
	}

	.header-transparent .navbar-brand {
		margin-right: 10px;
		padding: 10px 0px;
	}

	.our-clients .item {
		padding: 0 60px;
	}

	/* input-group */
	.input-group {
		width: 70px;
	}

	/* nav-tabs */
	.nav-tabs .nav-item .nav-link {
		margin-bottom: 15px;
	}

	.footer-top .social-icons li {
		padding-right: 25px;
	}

	.border-lr:before, .border-lr:after {
		width: 30%;
	}

}

@media (max-width:391px) {
	.team .team-detail {
		padding: 20px 20px 20px;
	}

	.owl-carousel .owl-nav i {
		width: 40px;
		height: 80px;
		line-height: 80px;
		font-size: 20px;
	}

	.feature-info.left-icon .feature-info-icon {
		width: 250px;
	}

	.feature-info .feature-info-icon i {
		font-size: 48px;
	}

	/* header-02 */
	.header.header-02 .navbar {
		padding: 0px 15px;
	}

	.header .navbar .add-listing .btn.btn-sm {
		padding: 6px 15px;
		font-size: 14px;
	}

	.header .add-listing {
		margin-right: 30px !important;
	}

	.our-clients .item {
		padding: 0 30px;
	}

}
